import useGlobalStore from '/src/stores/globalStore';
import { useNavigate } from 'react-router-dom';
import { Button } from '@widget-builder/src/components/ui';
import { useAuth0 } from '@auth0/auth0-react';

const LogOutButton = () => {
  const { setUser } = useGlobalStore();
  const navigate = useNavigate();
  const { logout: auth0Logout } = useAuth0();

  const handleClick = () => {
    // Clear all user-related state
    setUser(false);

    window.localStorage.clear();

    // If using Auth0, log out from there as well
    if (auth0Logout) {
      auth0Logout({
        returnTo: window.location.origin + '/login'
      });
      return;
    }

    // If not using Auth0, redirect to login page
    navigate('/login');
  };

  return (
    <Button 
      size="md" 
      variant="link" 
      className="no-underline bg-transparent border-0 cursor-pointer text-md hover:bg-transparent hover:text-orange-500 hover:no-underline focus:no-underline" 
      onClick={handleClick}
    >
      Log Out
    </Button>
  );
};

export default LogOutButton;

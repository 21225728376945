import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setCookie } from './../../utils/cookies';
import history from '../../utils/history';
import { post } from './../../utils/api';
import { UTF8 } from '../../utils/utf8';
import { getCurrentTenant } from '@mix/src/utils/Utils';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useGlobalStore from '../../stores/globalStore';

const setItem = window.localStorage.setItem.bind(window.localStorage);

const LoginForm = ({ initialValues = {}, passLoginEmail = () => {} }) => {
  const [showJWTform, setShowJWTform] = useState(true);
  const [showRedirectSSOmessage, setShowRedirectSSOmessage] = useState(false);
  const [loginEmail, setLoginEmail] = useState(initialValues.email || '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorSSO, setErrorSSO] = useState('');
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  const updateUserInfo = useGlobalStore(state => state.updateUserInfo);

  useEffect(() => {}, []);

  const handleSSOform = event => {
    event.preventDefault();

    if (loginEmail !== '') {
      setLoading(true);
      setErrorSSO('');
      passLoginEmail(loginEmail);

      fetch(`${import.meta.env.VITE_APP_REST_URI}/auth/method`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: loginEmail,
        }),
      })
        .then(res => res.json())
        .then(res => {
          setLoading(false);

          if (res.connection_name) {
            setShowRedirectSSOmessage(true);
            setItem('defaultTenantID', res.tenant_id);

            setTimeout(() => {
              loginWithRedirect({
                connection: res.connection_name,
                responseType: 'id_token',
                redirectUri: `${window.location.origin}/sso`,
              });
            }, 2000);
          }

          if (res.type === 'not_found') {
            setErrorSSO('Email not found.');
          }

          if (res.type === 'password') {
            setShowJWTform(false);
          }
        });
    }
  };

  const handleSSOAuthentication = () => {
    const nextPathname = history?.location?.state?.nextPathname;
    setCookie('credential', UTF8.decode(loginEmail.toLowerCase()).toString('base64'));
    post('/auth/login', {
      data: { email: loginEmail, password },
    })
      .then(async response => {
        const data = await response.json();
        return { data: data, status: response.status, error: response.error };
      })
      .then(response => {
        if (response.status === 200) {
          const { token, user, roles } = response.data;

          const userName = user.firstName;
          const role = roles.map(v => v.name);

          let defaultTenant;
          let next;

          const tenantSlugURL = nextPathname ? nextPathname.pathname.split('/')[1] : '/';

          if (tenantSlugURL) {
            defaultTenant = user.tenants.find(tenant => {
              return tenant.slug === getCurrentTenant(tenantSlugURL)
                ? tenant
                : tenant.projects.find(project => project.slug === tenantSlugURL);
            });

            next = nextPathname;

            if (!defaultTenant) {
              defaultTenant = user.tenants.find(tenant => tenant.slug === user.tenant);
              next = '/';
            }
          } else {
            defaultTenant = user.tenants.find(tenant => tenant.slug === user.tenant);
            next = '/';
          }

          updateUserInfo({
            name: userName,
            email: loginEmail,
            roles: role,
          });

          setItem('token', token);
          setItem('user', userName);
          setItem('email', loginEmail);
          setItem('roles', JSON.stringify(role));
          setItem('defaultTenantID', defaultTenant.id);
          setItem('defaultTenantSlug', defaultTenant.slug);

          navigate(next);
        } else {
          setErrorSSO(response.data.error);
        }
      });
  };

  const renderRedirectMessage = () => (
    <div className="z-10">
      <div className="text-center">
        <p className="text-lg text-white font-mslab_700">Redirecting...</p>
      </div>
    </div>
  );

  const renderJWTform = () => (
    <div className="">
      <div className="text-center">
        <div className="login-form" autoComplete="off">
          {errorSSO !== '' ? <div className="alert alert-danger">{errorSSO}</div> : null}
          <div className="flex flex-col">
            <input
              name="email"
              type="email"
              className="px-0 py-3 text-2xl text-white bg-transparent border-0 border-b border-b-white focus:border-b-white outline-none focus:outline-none focus:ring-0 font-mslab_700 [&::placeholder]:text-white/50"
              placeholder="Email Address"
              value={loginEmail}
              onChange={e => setLoginEmail(e.currentTarget.value)}
              autoFocus
              onKeyDown={e => e.key === 'Enter' && handleSSOform(e)}
            />

            <button
              color="primary"
              className="py-4 mt-4 text-xl bg-white font-mslab_700 hover:bg-black hover:text-white"
              onClick={handleSSOform}
              disabled={loading}
              type="submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const renderSSOform = () => {
    window.localStorage.setItem(
      'sso_redirect',
      history.location?.state?.nextPathname?.pathname
        ? history.location.state.nextPathname.pathname +
            history.location.state.nextPathname.search +
            history.location.state.nextPathname.hash
        : '/'
    );
    return (
      <>
        <div className="login-form-container">
          <div className="text-center">
            <div className="login-form" autoComplete="off">
              <div className="form-group">
                <input
                  name="password"
                  type="password"
                  placeholder="password"
                  className="form-control !ring-0 focus:border-white form-control-lg outline-none"
                  value={password}
                  onChange={e => setPassword(e.currentTarget.value)}
                  onKeyDown={e => e.key === 'Enter' && handleSSOAuthentication()}
                  autoFocus
                />

                {errorSSO && (
                  <div className="my-2">
                    <span className="p-1 text-xs text-white ">{errorSSO}</span>
                  </div>
                )}
              </div>

              <div className="form-group">
                <button
                  color="primary"
                  className="mt-3 btn btn-lg btn-submit text-uppercase"
                  onClick={handleSSOAuthentication}
                  disabled={loading}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  if (showRedirectSSOmessage) {
    return renderRedirectMessage();
  } else if (showJWTform) {
    return renderJWTform();
  } else if (!showJWTform) {
    return renderSSOform();
  } else {
    return null;
  }
};

export default LoginForm;

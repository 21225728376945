import { useNavigate } from 'react-router-dom';
import Logo from '/src/i/pickaxe.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import LoginChart from './LoginChart';

const SSO = () => {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, error, getIdTokenClaims } = useAuth0();
  const [userHasProjectAccess, setUserProjectAccess] = useState(false);
  const [defaultTenantName, setDefaultTenantName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const redirectReferrer = window.localStorage.getItem('sso_redirect') || '/';

  const contacts = {
    Telemundo: 'seamus.czprathstein@nbcuni.com',
  };

  useEffect(() => {
    const setItem = window.localStorage.setItem.bind(window.localStorage);

    const ssoOptions = props => {
      const { token, user, roles, permissions, message } = props;

      if (message) {
        setErrorMessage(message);
        return;
      }

      if (user) {
        let defaultTenant = user.tenants.find(tenant => tenant.slug === user.tenant);

        // check for referrer url and find tenant id
        if (redirectReferrer && !redirectReferrer.includes('/')) {
          const projectFromUrl = redirectReferrer?.split('/')[1];
          defaultTenant = user.tenants.find(tenant => tenant.projects.find(project => project.slug === projectFromUrl));
        }

        setDefaultTenantName(defaultTenant?.name);

        // Check if a user has access to at least one project
        if (defaultTenant?.projects?.length > 0) {
          setItem('token', token);
          setItem('user', user.firstName);
          setItem('email', user.email);
          setItem(
            'roles',
            roles.map(v => v.name)
          );
          setItem('permissions', JSON.stringify(permissions));
          setItem('defaultTenantID', defaultTenant.id);
          setItem('defaultTenantSlug', defaultTenant.slug);

          const defaultProject = defaultTenant.projects.find(project => project.slug === defaultTenant.slug);
          if (defaultProject) {
            setItem('currentProjectId', defaultProject.id);
          }

          // Clear the redirect URL from localStorage
          window.localStorage.removeItem('sso_redirect');

          // Navigate to the stored redirect path or default to home
          navigate(redirectReferrer);
        } else {
          setUserProjectAccess(true);
        }
      }
    };

    const callAPI = async () => {
      try {
        const claims = await getIdTokenClaims();
        const response = await fetch(`${import.meta.env.VITE_APP_REST_URI}/auth0/login`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id_token: claims.__raw,
          }),
        });

        const data = await response.json();
        ssoOptions(data);
      } catch (error) {
        console.error('Auth API error:', error);
        setErrorMessage('Failed to authenticate. Please try again.');
      }
    };

    if (isAuthenticated && !isLoading) {
      callAPI();
    }
  }, [isLoading, isAuthenticated, getIdTokenClaims, navigate, redirectReferrer]);

  // Generic authentication error
  const errorMessages = errorMessage => {
    let params = new URLSearchParams(document.location.search);
    let error_param = params.get('error');

    return (
      <>
        <h2 className="sso-title">There was a problem logging in.</h2>
        <p>Error: {error_param || errorMessage}</p>
        <p>
          Please return to the{' '}
          <a href="/" className="text-white">
            login
          </a>{' '}
          page and try again.
        </p>
      </>
    );
  };

  // Authenticated user without project access
  const accessMessage = () => {
    const contact = contacts[defaultTenantName];

    return (
      <>
        <h2 className="sso-title">Welcome To Pickaxe.</h2>
        <p>
          Sorry, it looks like you haven't been granted access to any projects yet. <br />
          Please contact your admin to get access:
        </p>
        <p>
          <b>{contact}</b>
        </p>
      </>
    );
  };

  return (
    <div className="flex flex-col min-h-screen md:flex-row">
      {/* Left Side - Branding */}
      <div className="order-1 w-full p-8 md:order-1 md:w-1/2 md:min-h-screen md:flex md:flex-col md:justify-between">
        <div className="text-orange">beta</div>

        <div className="max-w-md mx-auto my-8 md:my-0">
          <a href="/" className="inline-block mb-6">
            <img src={Logo} alt="Pickaxe" className="w-60" />
          </a>
          <h1 className="text-4xl font-bold text-black md:text-5xl font-mslab_700">
            It's your data. We just make it useful.
          </h1>
        </div>

        <div className="text-sm text-black">@ {new Date().getFullYear()} Pickaxe Foundry, Inc</div>
      </div>

      {/* Right Side - Login Form */}
      <div className="relative order-2 w-full min-h-[400px] md:order-2 md:w-1/2 md:min-h-screen bg-[#fe544b] pt-6 md:pt-0">
        {/* Chart positioned behind the form */}
        <div className="absolute inset-0 w-full h-full">
          <LoginChart />
        </div>

        {/* Form container with proper z-index */}
        <div className="relative z-10 flex flex-col items-center justify-center w-full h-full">
          {userHasProjectAccess && accessMessage()}
          {error && errorMessages()}
          {errorMessage && errorMessages(errorMessage)}
          {!error && !errorMessage && !userHasProjectAccess && (
            <div className="text-center">
              <p className="text-lg text-white font-mslab_700">Redirecting...</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SSO;

import axios from 'axios';
import * as echarts from 'echarts';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import pickaxe from '../src/styles/echarts-pickaxe-theme.js';
import '../src/styles/index.css';
import { createBrowserRouter, RouterProvider, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import NavMain from './components/Header/NavMain';
import { DashboardSkeleton } from './utils/DashboardSkeleton';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './pages/App';
import Login from './pages/Login';
import SSO from './pages/Login/SSO';
import NoData from '@widget-builder/src/components/ui/NoData';
import FallbackPage from './pages/FallbackPage';

const WidgetBuilder = lazy(() => import('@widget-builder').then(module => ({ default: module.WidgetBuilder })));
const Dashboard = lazy(() => import('@dashboard/pages/Dashboard'));
const Settings = lazy(() => import('@dashboard/pages/Settings'));
const MixBuilder = lazy(() => import('@mix').then(module => ({ default: module.MixBuilder })));
const QueryBuilder = lazy(() => import('@query-builder').then(module => ({ default: module.QueryBuilder })));
const DataSource = lazy(() => import('@data-sources').then(module => ({ default: module.DataSource })));
const MixAdmin = lazy(() => import('@mix-admin').then(module => ({ default: module.App })));

import { WidgetBuilderSkeleton } from '@dashboard/utils/WidgetBuilderSkeleton';

import NoMatch from './shared/NoMatch';

if (process.env.NODE_ENV === 'production') {
  window.addEventListener('error', function (event) {
    console.log('Original Error:', event.error);
    console.log('Error Stack:', event.error?.stack);
    console.log('Component Stack:', event.error?.componentStack);
  });
}

// Axios interceptor to add authorization headers
axios.interceptors.request.use(
  request => {
    // Get token on every request to ensure we have the latest
    const token = window.localStorage.getItem('token');
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
      request.headers['Content-Type'] = 'application/json';
    }
    return request;
  },
  error => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Update response interceptor to handle auth errors without removing token
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401 && !error.config.url.includes('/auth/login')) {
      console.warn('Authentication error:', error);
      // Don't remove token here, let the auth flow handle it
      // You might want to refresh the token if you have that capability
    }
    return Promise.reject(error);
  }
);

// Define ProtectedRoute before the router configuration
const ProtectedRoute = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  const token = window.localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if token exists on mount and component updates
    const currentToken = window.localStorage.getItem('token');
    if (!currentToken && !isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  if (isLoading) {
    return <DashboardSkeleton />;
  }

  // Allow access if either Auth0 is authenticated OR we have a valid token
  if (isAuthenticated || token) {
    return <App />;
  }

  return <Navigate to="/login" replace />;
};

// Register the ECharts theme
echarts.registerTheme('pickaxe', pickaxe);

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/sso',
    element: <SSO />,
  },
  {
    path: '/',
    element: <ProtectedRoute />,
    children: [
      {
        path: 'fallback',
        element: <FallbackPage />,
      },
      {
        index: true,
        element: (
          <Suspense fallback={<DashboardSkeleton />}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: ':project/:board?',
        element: (
          <Suspense fallback={<DashboardSkeleton />}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: 'query',
        element: (
          <Suspense fallback={<DashboardSkeleton />}>
            <QueryBuilder />
          </Suspense>
        ),
      },
      {
        path: 'mix',
        element: (
          <Suspense fallback={<DashboardSkeleton />}>
            <MixBuilder />
          </Suspense>
        ),
      },
      {
        path: 'mix-admin',
        element: (
          <Suspense fallback={<DashboardSkeleton />}>
            <MixAdmin />
          </Suspense>
        ),
      },
      {
        path: 'settings/*',
        element: (
          <Suspense fallback={<DashboardSkeleton />}>
            <Settings />
          </Suspense>
        ),
      },
      { path: '*', element: <NoMatch /> },
    ],
  },
  {
    path: '/builder/:projectSlug?/:widgetId?/:boardSlug?',
    element: (
      <Suspense fallback={<WidgetBuilderSkeleton />}>
        <WidgetBuilder />
      </Suspense>
    ),
  },
  {
    path: '/settings/data-sources/*',
    element: (
      <Suspense fallback={<DashboardSkeleton />}>
        <DataSource />
      </Suspense>
    ),
  },
  {
    path: '/no-data',
    element: (
      <Suspense fallback={<DashboardSkeleton />}>
        <NavMain />
        <NoData />
      </Suspense>
    ),
  },
]);

const container = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
    },
  },
});

// Render the application
root.render(
  <Auth0Provider
    domain={import.meta.env.VITE_APP_OAUTH_DOMAIN}
    clientId={import.meta.env.VITE_APP_OAUTH_CLIENT_ID}
    redirectUri={`${window.location.origin}/sso`}
    cacheLocation="localstorage"
    useRefreshTokens={false}
    skipRedirectCallback={window.localStorage.getItem('token') !== null}
  >
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </QueryClientProvider>
  </Auth0Provider>
);

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Logo from '/src/i/pickaxe.svg';
import LoginForm from './LoginForm';
import LoginChart from './LoginChart';

function Login() {
  const { isAuthenticated } = useAuth0();
  const { state } = useLocation();
  const { from } = state || { from: { pathname: '/' } };
  const [loginEmail, setLoginEmail] = useState('');

  if (isAuthenticated) {
    return <Navigate to={from} />;
  }

  return (
    <div className="flex flex-col min-h-screen md:flex-row">
      {/* Left Side - Branding */}
      <div className="order-1 w-full p-8 md:order-1 md:w-1/2 md:min-h-screen md:flex md:flex-col md:justify-between">
        <div className="text-orange">beta</div>
        
        <div className="max-w-md mx-auto my-8 md:my-0">
          <a href="/" className="inline-block mb-6">
            <img src={Logo} alt="Pickaxe" className="w-60" />
          </a>
          <h1 className="text-4xl font-bold text-black md:text-5xl font-mslab_700">
            It's your data. We just make it useful.
          </h1>
        </div>

        <div className="text-sm text-black">
          @ {new Date().getFullYear()} Pickaxe Foundry, Inc
        </div>
      </div>

      {/* Right Side - Login Form */}
      <div className="relative order-2 w-full min-h-[400px] md:order-2 md:w-1/2 md:min-h-screen bg-[#fe544b] pt-6 md:pt-0">
        {/* Chart positioned behind the form */}
        <div className="absolute inset-0 w-full h-full">
          <LoginChart />
        </div>
        
        {/* Form container with proper z-index */}
        <div className="relative z-10 flex flex-col items-center justify-center w-full h-full">
          <LoginForm 
            initialValues={{ email: loginEmail }}
            passLoginEmail={setLoginEmail}
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
